import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { EGDSSheetContent, EGDSSheet, EGDSSheetTransition } from "@egds/react-core/sheet";
import { EGDSToolbar } from "@egds/react-core/toolbar";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem, MinMax, Repeat } from "@egds/react-core/layout-grid";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { ThumbnailGalleryDialogProps } from "../../typings";

export const ThumbnailGalleryDialog = (props: ThumbnailGalleryDialogProps) => {
  const { dialogId, imageElements, title, triggerThumbnailRef } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();

  const [isThumbnailGalleryDialogOpen, thumbnailsDialogActions, ThumbnailsGalleryDialogComponent] = useDialog(dialogId);

  const closeThumbnailsDialog = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarouselThumbnails",
      rfrr: "carousel.gallery.thumbnails.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    thumbnailsDialogActions.closeDialog();

    document.getElementById("galleryCarouselOpenButton")?.focus();
  };

  const thumbnailSmallView = (
    <EGDSLayoutGrid columns={[Repeat(3, MinMax("24x", "1fr"))]} autoRows={["30x"]} space="two">
      {imageElements.map((image, index) => (
        <EGDSLayoutGridItem key={`thumbnail-image-${index}`}>{image}</EGDSLayoutGridItem>
      ))}
    </EGDSLayoutGrid>
  );

  const thumbnailMediumView = (
    <EGDSLayoutGrid columns={[Repeat(5, MinMax("24x", "1fr"))]} autoRows={["30x"]} space="two">
      {imageElements.map((image, index) => (
        <EGDSLayoutGridItem key={`thumbnail-image-${index}`}>{image}</EGDSLayoutGridItem>
      ))}
    </EGDSLayoutGrid>
  );

  return (
    <Viewport>
      <ViewSmall>
        <EGDSSheetTransition isVisible={isThumbnailGalleryDialogOpen}>
          <ThumbnailsGalleryDialogComponent>
            <EGDSSheet isVisible={isThumbnailGalleryDialogOpen} type="full" triggerRef={triggerThumbnailRef}>
              <EGDSToolbar
                navigationContent={{
                  onClick: closeThumbnailsDialog,
                  navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
                }}
                toolbarTitle={title}
                elevation="flat"
              />
              <EGDSSpacing padding={{ inline: "three", blockend: "three" }}>
                <EGDSSheetContent padded={false}>{thumbnailSmallView}</EGDSSheetContent>
              </EGDSSpacing>
            </EGDSSheet>
          </ThumbnailsGalleryDialogComponent>
        </EGDSSheetTransition>
      </ViewSmall>
      <ViewMedium>
        <EGDSSheetTransition isVisible={isThumbnailGalleryDialogOpen}>
          <ThumbnailsGalleryDialogComponent>
            <EGDSSheet isVisible={isThumbnailGalleryDialogOpen} type="centered" triggerRef={triggerThumbnailRef}>
              <EGDSToolbar
                navigationContent={{
                  onClick: closeThumbnailsDialog,
                  navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
                }}
                toolbarTitle={title}
                elevation="flat"
              />
              <EGDSSpacing padding={{ inline: "three", blockend: "three" }}>
                <EGDSSheetContent padded={false}>{thumbnailMediumView}</EGDSSheetContent>
              </EGDSSpacing>
            </EGDSSheet>
          </ThumbnailsGalleryDialogComponent>
        </EGDSSheetTransition>
      </ViewMedium>
    </Viewport>
  );
};

export default ThumbnailGalleryDialog;
