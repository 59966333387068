import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { EGDSSheetContent, EGDSSheet, EGDSSheetTransition } from "@egds/react-core/sheet";
import { EGDSTransparentToolbar } from "@egds/react-core/toolbar";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { EGDSCarousel, EGDSCarouselProps } from "@egds/react-core/carousel";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";
import { MediaGalleryDialogProps } from "../../typings";

export const MediaGalleryDialog = (props: MediaGalleryDialogProps) => {
  const { carouselProps, currentIndex, dialogId, images, startIndex } = props;
  const { formatText } = useLocalization();
  const track = useClickTracker();
  const triggerMediaRef = React.useRef<HTMLElement | null>(null);

  const [isMediaGalleryDialogOpen, mediaDialogActions, MediaGalleryDialogComponent] = useDialog(dialogId);

  const closeMediaGalleryDialog = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryCarousel",
      rfrr: "carousel.gallery.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateClickstreamTrackEvent(trackingInfo, track);
    mediaDialogActions.closeDialog();
  };

  const galleryImageElements = images.map((image, index) => (
    <EGDSFigure ratio={EGDSFigureAspectRatioType.R16_9} key={`carousel-image-${index}`}>
      <EGDSImage alt={image.alt} src={image.src} />
    </EGDSFigure>
  ));

  const mediaCarouselProps: EGDSCarouselProps = {
    ...carouselProps,
    children: galleryImageElements,
  };

  return (
    <EGDSSheetTransition isVisible={isMediaGalleryDialogOpen}>
      <MediaGalleryDialogComponent>
        <EGDSSheet isVisible={isMediaGalleryDialogOpen} type="full" triggerRef={triggerMediaRef} theme="dark">
          <EGDSTransparentToolbar
            overlay
            navigationContent={{
              onClick: closeMediaGalleryDialog,
              navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
            }}
          />
          <EGDSSheetContent padded={false}>
            <EGDSLayoutFlex direction="column" blockSize="full_size">
              <EGDSLayoutFlexItem>
                <EGDSSpacing padding={{ blockstart: "twelve" }}>
                  <EGDSCarousel
                    {...mediaCarouselProps}
                    fullSheetVisible={isMediaGalleryDialogOpen}
                    startIndex={startIndex}
                  />
                </EGDSSpacing>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSSpacing padding={{ block: "four", inline: "three" }}>
                  <EGDSLayoutFlex justifyContent="space-between" alignItems="end">
                    <EGDSLayoutFlexItem>
                      <EGDSText theme="inverse">{images[currentIndex].caption || ""}</EGDSText>
                    </EGDSLayoutFlexItem>
                    <EGDSLayoutFlexItem>
                      <EGDSText theme="inverse">{String(currentIndex + 1) + "/" + String(images.length)}</EGDSText>
                    </EGDSLayoutFlexItem>
                  </EGDSLayoutFlex>
                </EGDSSpacing>
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSSheetContent>
        </EGDSSheet>
      </MediaGalleryDialogComponent>
    </EGDSSheetTransition>
  );
};

export default MediaGalleryDialog;
